.heart-icon {
  height: 50px;
  width: 50px;
  background: url('../animation/heart.png');
  background-size: cover;
  background-position: left;
  cursor: pointer;
  position: absolute;
  right: -10px;
  margin-top: 1px;
}

.heart-bg {
  background: rgba(255, 192, 200, 0);
  border-radius: 15px;
  height: 40px;
  min-width: 40px;
  width: auto;
  display: flex;
  align-items: center;

  transition: all 100ms ease;
  position: relative;
}

.heart-bg:hover {
  background: rgba(255, 192, 200, 0.7);
}

.heart-icon.liked {
  animation: like-anim 0.7s steps(28) forwards;
}

@keyframes like-anim {
  to {
    background-position: right;
  }
}

.likes-amount.liked {
  color: red;
}
